"use client";

import Autoplay from "embla-carousel-autoplay";

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselPrevious,
    CarouselNext,
} from "@/components/ui/carousel";
import { landingPageContent } from "@/lib/landingPageContent";
import TailwindAdvancedEditor from "@/components/tailwind/advanced-editor";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export const LandingPageCarousel = () => {
    return (
        <Carousel
            plugins={[
                Autoplay({
                    delay: 5000,
                }),
            ]}
            className={"flex flex-col gap-2"}
        >
            {/* <div className="flex flex-row items-center gap-2">
                <CarouselPrevious />
                <CarouselNext />
            </div> */}

            <CarouselContent className="sm:shadow-xl">
                {landingPageContent.map((content, index) => {
                    return (
                        <CarouselItem
                            key={index}
                            className="flex flex-col gap-4"
                        >
                            <div className="flex flex-col">
                                <span className="text-sm font-semibold">
                                    {content.ownerName}
                                </span>
                                <span className="text-xs font-medium text-gray-400">
                                    {content.ownerRole}
                                </span>
                                <span className="text-sm font-normal mt-1">
                                    {content.ownerBio}
                                </span>
                            </div>
                            <TailwindAdvancedEditor
                                loadedContent={content}
                                backgroundColor={content.backgroundColor}
                                isEditable={false}
                            />
                        </CarouselItem>
                    );
                })}
            </CarouselContent>
        </Carousel>
    );
};
