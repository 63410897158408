import { type JSONContent } from "novel";
import { string } from "zod";

export const landingPageContent: (JSONContent & {
    backgroundColor: string;
    name: string;
    ownerImage: string;
    ownerName: string;
    ownerRole: string;
    ownerBio: string;
})[] = [
    {
        ownerImage: "/denis.png",
        ownerName: "Denis C.",
        ownerRole: "Head of Marketing, Tropic Talent",
        ownerBio:
            "Denis shares latest updates and recruiting news with his clients based in Miami, FL through Bloom",
        name: "recruiting-agency-newsletter",
        backgroundColor: "#ffe5ec",
        type: "doc",
        content: [
            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 1,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#9333EA",
                                },
                            },
                        ],
                        text: "Tropic Talent Solutions →  ",
                    },
                ],
            },
            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 3,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-purple)",
                                },
                            },
                        ],
                        text: "Welcome to our March 2024 edition ",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "left",
                },
                content: [
                    {
                        type: "text",
                        text: "At Tropical Talent, the future of talent meets Miami's vibrant business landscape. As industry leaders, you know that the backbone of any successful enterprise is its people. That's where we step in.",
                    },
                ],
            },
            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 3,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-purple)",
                                },
                            },
                        ],
                        text: "Our newest hires - Tay, Emily",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "left",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "italic",
                            },
                        ],
                        text: "Aadam, hired by Bernard ",
                    },
                    {
                        type: "text",
                        text: "for Bookface Inc. ",
                    },
                ],
            },
            {
                type: "image",
                attrs: {
                    textAlign: "left",
                    src: "https://bloomai-newsletterimages.s3.us-east-1.amazonaws.com/29049c6a-4eab-4eef-bab9-0764822a1a8b~jud-mackrill-Of_m3hMsoAA-unsplash.jpg",
                    alt: null,
                    title: null,
                    width: null,
                    height: null,
                },
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "start",
                },
                content: [
                    {
                        type: "text",
                        text: "Emily, hired by Choi for WaterDnd Inc. ",
                    },
                ],
            },
            {
                type: "image",
                attrs: {
                    textAlign: "left",
                    src: "https://bloomai-newsletterimages.s3.us-east-1.amazonaws.com/44218259-d864-46ab-b372-7885123ccae3~Screenshot 2024-03-25 at 5.13.17 PM.png",
                    alt: null,
                    title: null,
                    width: null,
                    height: null,
                },
            },
            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 3,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-purple)",
                                },
                            },
                        ],
                        text: "Hooman Reese-ources pro-tip 🥜",
                    },
                ],
            },
            {
                type: "codeBlock",
                attrs: {
                    language: null,
                },
                content: [
                    {
                        type: "text",
                        text: "AI block:\nYou are trained on all documents from Tropical Talent Solutions. Find effective HR strategies for large companies to utilize and suggest strategies that have worked successfully in the past.",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "center",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "link",
                                attrs: {
                                    href: "https://www.facebook.com/",
                                    target: "_blank",
                                    rel: "noopener noreferrer nofollow",
                                    class: "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer",
                                },
                            },
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#2563EB",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: " Facebook",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#FFA500",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "  | ",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "link",
                                attrs: {
                                    href: "https://www.instagram.com/",
                                    target: "_blank",
                                    rel: "noopener noreferrer nofollow",
                                    class: "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer",
                                },
                            },
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#BA4081",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "Instagram",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#FFA500",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "  |  ",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "link",
                                attrs: {
                                    href: "https://www.tiktok.com/",
                                    target: "_blank",
                                    rel: "noopener noreferrer nofollow",
                                    class: "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer",
                                },
                            },
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#FFA500",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "TikTok",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "center",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "rgb(13, 13, 13)",
                                },
                            },
                        ],
                        text: "Tropic Talent Solutions LLC. ©  All rights reserved.",
                    },
                ],
            },
        ],
    },
    {
        ownerImage: "/jordan.png",
        ownerName: "Jordan L.",
        ownerRole: "CEO, ATX Homebuilders Inc.",
        ownerBio:
            "Jordan uses Bloom to nurture relationships with his clients and share the latest updates from his homebuilding company.",
        name: "homebuilders-newsletter",
        backgroundColor: "#f8edeb",
        type: "doc",
        content: [
            {
                type: "image",
                attrs: {
                    textAlign: "left",
                    src: "https://bloomai-newsletterimages.s3.us-east-1.amazonaws.com/22c92b79-7863-49bc-bac8-712af931936d~images.jpeg",
                    alt: null,
                    title: null,
                    width: 800,
                    height: 420.698,
                },
            },
            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 1,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#008A00",
                                },
                            },
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-yellow)",
                                },
                            },
                        ],
                        text: "ATX homebuilders & landscapers 🏡",
                    },
                ],
            },
            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 3,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-purple)",
                                },
                            },
                        ],
                        text: "Welcome to our Feb 2024 edition",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "left",
                },
                content: [
                    {
                        type: "text",
                        text: "Whether you're dreaming of a cozy nook by the window or planning a grand renovation that transforms your living space, we're here to illuminate the path from concept to completion. Join us as we explore the latest trends in design, share expert advice, and reveal the magic behind turning houses into homes.",
                    },
                ],
            },
            { type: "horizontalRule" },

            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 3,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-purple)",
                                },
                            },
                        ],
                        text: "Our latest successes ",
                    },
                ],
            },
            {
                type: "orderedList",
                attrs: {
                    tight: true,
                    start: 1,
                },
                content: [
                    {
                        type: "listItem",
                        content: [
                            {
                                type: "paragraph",
                                attrs: {
                                    textAlign: "left",
                                },
                                content: [
                                    {
                                        type: "text",
                                        marks: [
                                            {
                                                type: "italic",
                                            },
                                        ],
                                        text: "Cerquiera family, Rosewood Avenue",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                type: "blockquote",
                content: [
                    {
                        type: "paragraph",
                        attrs: {
                            textAlign: "left",
                        },
                        content: [
                            {
                                type: "text",
                                marks: [
                                    {
                                        type: "textStyle",
                                        attrs: {
                                            color: "#A8A29E",
                                        },
                                    },
                                ],
                                text: "Our dream home on Rosewood Avenue, crafted by ATX homebuilders, exceeded all expectations. Their dedication turned our vision into reality, creating a home we truly love.",
                            },
                        ],
                    },
                ],
            },
            {
                type: "image",
                attrs: {
                    textAlign: "left",
                    src: "https://bloomai-newsletterimages.s3.us-east-1.amazonaws.com/2457ee79-334d-4788-9173-22eed98b456c~Screenshot 2024-03-25 at 5.49.21 PM.png",
                    alt: null,
                    title: null,
                    width: null,
                    height: null,
                },
            },
            {
                type: "orderedList",
                attrs: {
                    tight: true,
                    start: 2,
                },
                content: [
                    {
                        type: "listItem",
                        content: [
                            {
                                type: "paragraph",
                                attrs: {
                                    textAlign: "left",
                                },
                                content: [
                                    {
                                        type: "text",
                                        text: "Debbie & Tyler’s home renovations, 1400 Lavaca St",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                type: "blockquote",
                content: [
                    {
                        type: "paragraph",
                        attrs: {
                            textAlign: "left",
                        },
                        content: [
                            {
                                type: "text",
                                marks: [
                                    {
                                        type: "textStyle",
                                        attrs: {
                                            color: "#A8A29E",
                                        },
                                    },
                                ],
                                text: "They transformed our renovation journey at our Lavaca house into a seamless and delightful experience, resulting in a home that's beyond our dreams.",
                            },
                        ],
                    },
                ],
            },
            {
                type: "image",
                attrs: {
                    textAlign: "left",
                    src: "https://bloomai-newsletterimages.s3.us-east-1.amazonaws.com/926102f9-1cc9-4b7f-be69-b725215b54e3~phil-hearing-IYfp2Ixe9nM-unsplash.jpg",
                    alt: null,
                    title: null,
                    width: null,
                    height: null,
                },
            },
            {
                type: "orderedList",
                attrs: {
                    tight: true,
                    start: 3,
                },
                content: [
                    {
                        type: "listItem",
                        content: [
                            {
                                type: "paragraph",
                                attrs: {
                                    textAlign: "left",
                                },
                                content: [
                                    {
                                        type: "text",
                                        text: "Miguel & family, 5th & Congress.",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                type: "blockquote",
                content: [
                    {
                        type: "paragraph",
                        attrs: {
                            textAlign: "left",
                        },
                        content: [
                            {
                                type: "text",
                                marks: [
                                    {
                                        type: "textStyle",
                                        attrs: {
                                            color: "#A8A29E",
                                        },
                                    },
                                ],
                                text: "Wow! Thank you. Our family home now perfectly captures the vibrant spirit of its location with unparalleled craftsmanship and innovation.",
                            },
                        ],
                    },
                ],
            },
            {
                type: "image",
                attrs: {
                    textAlign: "left",
                    src: "https://bloomai-newsletterimages.s3.us-east-1.amazonaws.com/e0683433-6cf9-4814-8a76-e856d5f33b73~francesca-tosolini-XcVm8mn7NUM-unsplash.jpg",
                    alt: null,
                    title: null,
                    width: null,
                    height: null,
                },
            },
            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 3,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#EAB308",
                                },
                            },
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-orange)",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "Your monthly home improvement checklist  ",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#EAB308",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "✅",
                    },
                ],
            },
            {
                type: "taskList",
                content: [
                    {
                        type: "taskItem",
                        attrs: {
                            checked: false,
                        },
                        content: [
                            {
                                type: "paragraph",
                                attrs: {
                                    textAlign: "left",
                                },
                                content: [
                                    {
                                        type: "text",
                                        marks: [
                                            {
                                                type: "bold",
                                            },
                                        ],
                                        text: "Check for Leaks:",
                                    },
                                    {
                                        type: "text",
                                        text: " Inspect faucets, showerheads, and toilets for leaks and repair as needed.",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "taskItem",
                        attrs: {
                            checked: false,
                        },
                        content: [
                            {
                                type: "paragraph",
                                attrs: {
                                    textAlign: "left",
                                },
                                content: [
                                    {
                                        type: "text",
                                        marks: [
                                            {
                                                type: "bold",
                                            },
                                        ],
                                        text: "Inspect Roof:",
                                    },
                                    {
                                        type: "text",
                                        text: " Look for missing or damaged shingles and potential leaks.",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                type: "codeBlock",
                attrs: {
                    language: null,
                },
                content: [
                    {
                        type: "text",
                        text: "AI block:\nGenerate a list of monthly home improvement tips based on the checklist above",
                    },
                ],
            },
            { type: "horizontalRule" },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "center",
                },
                content: [
                    {
                        type: "text",
                        text: "Connect with us on ⬇️",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "center",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "link",
                                attrs: {
                                    href: "https://www.facebook.com/",
                                    target: "_blank",
                                    rel: "noopener noreferrer nofollow",
                                    class: "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer",
                                },
                            },
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#2563EB",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: " Facebook",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#FFA500",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "  | ",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "link",
                                attrs: {
                                    href: "https://www.instagram.com/",
                                    target: "_blank",
                                    rel: "noopener noreferrer nofollow",
                                    class: "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer",
                                },
                            },
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#BA4081",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "Instagram",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#FFA500",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "  |  ",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "link",
                                attrs: {
                                    href: "https://www.tiktok.com/",
                                    target: "_blank",
                                    rel: "noopener noreferrer nofollow",
                                    class: "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer",
                                },
                            },
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#FFA500",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "TikTok",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "center",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "rgb(13, 13, 13)",
                                },
                            },
                        ],
                        text: "ATX Homebuilding LLC. 2024 ©  All rights reserved.",
                    },
                ],
            },
        ],
    },
    {
        name: "coffee-shop-newsletter",
        backgroundColor: "#faedcd",
        type: "doc",
        ownerImage: "/amie.png",
        ownerName: "Amie K",
        ownerRole: "Owner, Nook Cafe",
        ownerBio:
            "Amie uses Bloom to keep in touch with her customers and share the latest updates from her coffee shop.",
        content: [
            {
                type: "heading",
                attrs: {
                    textAlign: "center",
                    level: 1,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-pink)",
                                },
                            },
                        ],
                        text: "The Daily Brew ☕️",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "left",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "rgb(31, 31, 31)",
                                },
                            },
                            {
                                type: "italic",
                            },
                        ],
                        text: "Nook Cafe, ",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "rgb(31, 31, 31)",
                                },
                            },
                            {
                                type: "bold",
                            },
                            {
                                type: "italic",
                            },
                        ],
                        text: "1500 Hyde St, San Francisco, CA 94109",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "center",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "link",
                                attrs: {
                                    href: "https://www.facebook.com/",
                                    target: "_blank",
                                    rel: "noopener noreferrer nofollow",
                                    class: "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer",
                                },
                            },
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#2563EB",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "Facebook",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#FFA500",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "  |  ",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "link",
                                attrs: {
                                    href: "https://www.instagram.com/",
                                    target: "_blank",
                                    rel: "noopener noreferrer nofollow",
                                    class: "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer",
                                },
                            },
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#BA4081",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "Instagram",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#FFA500",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "  |  ",
                    },
                    {
                        type: "text",
                        marks: [
                            {
                                type: "link",
                                attrs: {
                                    href: "https://www.tiktok.com/",
                                    target: "_blank",
                                    rel: "noopener noreferrer nofollow",
                                    class: "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer",
                                },
                            },
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "#FFA500",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "TikTok",
                    },
                ],
            },
            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 3,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-pink)",
                                },
                            },
                        ],
                        text: "Our weekly coffee recipe",
                    },
                ],
            },
            {
                type: "codeBlock",
                attrs: {
                    language: null,
                },
                content: [
                    {
                        type: "text",
                        text: "AI block:\nSuggest a new coffee recipe every week with the basic outlines, choose from our coffee catalog",
                    },
                ],
            },
            { type: "horizontalRule" },
            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 3,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-pink)",
                                },
                            },
                        ],
                        text: "Our weekly special",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "left",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "italic",
                            },
                        ],
                        text: "Caramel Macchiato made by Sam ",
                    },
                    {
                        type: "text",
                        text: "👯‍♀️",
                    },
                ],
            },
            {
                type: "image",
                attrs: {
                    textAlign: "left",
                    src: "https://bloomai-newsletterimages.s3.us-east-1.amazonaws.com/01b1ab9d-7880-4f0c-aec2-89426915e76f~9bc75132-4449-4087-855b-7c1756b04825.jpeg",
                    alt: null,
                    title: null,
                    width: 402,
                    height: 368.429,
                },
            },
            { type: "horizontalRule" },

            {
                type: "heading",
                attrs: {
                    textAlign: "left",
                    level: 3,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "highlight",
                                attrs: {
                                    color: "var(--novel-highlight-pink)",
                                },
                            },
                        ],
                        text: "Coffee shop updates",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "left",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "italic",
                            },
                        ],
                        text: "Hey coffee family! 🌟 Just wanted to give y’all a quick heads-up: our cozy little corner is getting a bit of a facelift! Yep, we’re sprucing up the place to make our hangouts even more special. ",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "start",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "italic",
                            },
                        ],
                        text: "You might bump into a ladder or hear a bit more buzzing and hammering than our usual coffee grinding tunes. But don’t worry, we’re still here, brewing your favorite cuppas and flashing our brightest smiles. :-)",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "start",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "rgb(13, 13, 13)",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "Warm wishes and even warmer coffee,",
                    },
                ],
            },
            {
                type: "paragraph",
                attrs: {
                    textAlign: "start",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "rgb(13, 13, 13)",
                                },
                            },
                            {
                                type: "bold",
                            },
                        ],
                        text: "The Nook Café team",
                    },
                ],
            },
            { type: "horizontalRule" },

            {
                type: "paragraph",
                attrs: {
                    textAlign: "start",
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "textStyle",
                                attrs: {
                                    color: "rgb(13, 13, 13)",
                                },
                            },
                        ],
                        text: "P.S. We love hearing from you! Hit reply to share your thoughts, stories, or just to say hi. Let's keep the conversation brewing.",
                    },
                ],
            },
        ],
    },
];
