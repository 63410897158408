"use client";
import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
    EditorRoot,
    EditorCommand,
    EditorCommandItem,
    EditorCommandEmpty,
    EditorContent,
    type JSONContent,
    EditorInstance,
    EditorCommandList,
    useEditor,
} from "novel";
import { ImageResizer, handleCommandNavigation } from "novel/extensions";
import { defaultExtensions } from "./extensions";
import { Separator } from "./ui/separator";
import { NodeSelector } from "./selectors/node-selector";
import { LinkSelector } from "./selectors/link-selector";
import { ColorSelector } from "./selectors/color-selector";

import { TextButtons } from "./selectors/text-buttons";
import { suggestionItems } from "./slash-command";
import GenerativeMenuSwitch from "./generative/generative-menu-switch";
import { handleImageDrop, handleImagePaste } from "novel/plugins";
import { uploadFn } from "./image-upload";
import { Editor } from "@tiptap/react";
import LiftEditorState from "../Business/LiftEditorState";
import PreviewTabs from "../Business/PreviewTabs";
import objectHash from "object-hash";

export type EditorStatus = "Saved" | "Unsaved";

interface TailwindAdvancedEditorProps {
    updateSaveStatus?: (status: EditorStatus) => void;
    saveStatus?: EditorStatus;
    isEditable?: boolean;
    backgroundColor?: string;
    loadedContent?: JSONContent;
    isFullLength?: boolean;
    onEditorUpdate?: (editor: EditorInstance) => void;
    toolbar?: React.ReactNode;
    onMountEditor?: (editor: Editor) => void;
    isPublished?: boolean;
    showPreviewSwitch?: boolean;
}

const TailwindAdvancedEditor: React.FC<TailwindAdvancedEditorProps> = ({
    updateSaveStatus,
    saveStatus,
    isEditable,
    backgroundColor,
    loadedContent,
    isFullLength,
    onEditorUpdate,
    toolbar,
    onMountEditor,
    isPublished,
    showPreviewSwitch,
}) => {
    const [openNode, setOpenNode] = useState(false);
    const [openColor, setOpenColor] = useState(false);
    const [openLink, setOpenLink] = useState(false);
    const [openAI, setOpenAI] = useState(false);

    const debouncedUpdates = useDebouncedCallback(
        async (editor: EditorInstance) => {
            if (onEditorUpdate) {
                onEditorUpdate(editor);
            }
            !!updateSaveStatus && updateSaveStatus("Saved");
        },
        1000
    );

    // const [content, setContent] = useState(loadedContent);

    // useEffect(() => {
    //     console.log("reaching use effect");
    //     setContent(loadedContent);
    // }, [loadedContent?.content?.length]);

    if (!loadedContent) return null;

    const [tab, setTab] = useState<PreviewLayoutTypes>("web");

    const onTabChange = (value: PreviewLayoutTypes) => {
        setTab(value);
    };

    return (
        <div
            className={`relative w-full ${
                tab === "web" ? "max-w-screen-lg" : "max-w-[380px]"
            }`}
        >
            <div className="flex flex-row items-start justify-between absolute top-0 z-10 w-full">
                {isPublished ? (
                    <div className="rounded-lg h-7 px-2 py-1 text-xs text-muted-foreground">
                        Published template
                    </div>
                ) : isPublished === false ? (
                    <div className="rounded-lg h-7 px-2 py-1 text-xs text-muted-foreground">
                        Unpublished template
                    </div>
                ) : null}
                <div className="flex flex-row gap-1 h-fit">
                    {saveStatus != undefined && (
                        <div className="rounded-lg h-7 px-2 py-1 text-xs text-muted-foreground">
                            {saveStatus}
                        </div>
                    )}
                    {showPreviewSwitch && (
                        <PreviewTabs tab={tab} setTab={onTabChange} />
                    )}
                </div>
            </div>
            <EditorRoot>
                <div
                    className={`sm:rounded-md ${
                        !isFullLength ? "h-[600px] overflow-scroll" : undefined
                    } sm:shadow-xl overflow-x-hidden`}
                    style={{
                        backgroundColor: backgroundColor ?? "#fff",
                    }}
                >
                    <EditorContent
                        key={objectHash(loadedContent)}
                        editable={!!isEditable}
                        initialContent={loadedContent}
                        extensions={[...defaultExtensions]}
                        className="relative min-h-[600px] w-full max-w-screen-lg border-muted bg-transparent sm:rounded-lg sm:border"
                        editorProps={{
                            handleDOMEvents: {
                                keydown: (_view, event) =>
                                    handleCommandNavigation(event),
                            },
                            handlePaste: (view, event) =>
                                handleImagePaste(view, event, uploadFn),
                            handleDrop: (view, event, _slice, moved) =>
                                handleImageDrop(view, event, moved, uploadFn),
                            attributes: {
                                class: `prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full`,
                            },
                        }}
                        onUpdate={({ editor }) => {
                            debouncedUpdates(editor);
                            !!updateSaveStatus && updateSaveStatus("Unsaved");
                        }}
                        slotBefore={toolbar}
                        slotAfter={isEditable ? <ImageResizer /> : undefined}
                    >
                        {onMountEditor && (
                            <LiftEditorState onMountEditor={onMountEditor} />
                        )}
                        <EditorCommand className="z-50 h-auto max-h-[330px] overflow-y-auto rounded-md border border-muted bg-background px-1 py-2 shadow-md transition-all">
                            <EditorCommandEmpty className="px-2 text-muted-foreground">
                                No results
                            </EditorCommandEmpty>
                            <EditorCommandList>
                                {suggestionItems.map((item) => (
                                    <EditorCommandItem
                                        value={item.title}
                                        onCommand={(val) => item.command!(val)}
                                        className={`flex w-full items-center space-x-2 rounded-md px-2 py-1 text-left text-sm hover:bg-accent aria-selected:bg-accent `}
                                        key={item.title}
                                    >
                                        <div className="flex h-10 w-10 items-center justify-center rounded-md border border-muted bg-background">
                                            {item.icon}
                                        </div>
                                        <div>
                                            <p className="font-medium">
                                                {item.title}
                                            </p>
                                            <p className="text-xs text-muted-foreground">
                                                {item.description}
                                            </p>
                                        </div>
                                    </EditorCommandItem>
                                ))}
                            </EditorCommandList>
                        </EditorCommand>

                        <GenerativeMenuSwitch
                            open={openAI}
                            onOpenChange={setOpenAI}
                        >
                            <Separator orientation="vertical" />
                            <NodeSelector
                                open={openNode}
                                onOpenChange={setOpenNode}
                            />
                            <Separator orientation="vertical" />

                            <LinkSelector
                                open={openLink}
                                onOpenChange={setOpenLink}
                            />
                            <Separator orientation="vertical" />
                            <TextButtons />
                            <Separator orientation="vertical" />
                            <ColorSelector
                                open={openColor}
                                onOpenChange={setOpenColor}
                            />
                        </GenerativeMenuSwitch>
                    </EditorContent>
                </div>
            </EditorRoot>
        </div>
    );
};

export default TailwindAdvancedEditor;
